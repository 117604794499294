<template>
	<div>
		<div v-if="!store.isApp" class="footnoteHome bg-color-white d-flex justify-content-center">
		    <div class="col-11 col-xl-8 foot-content">
		        <div class="content d-flex justify-content-between">
		            <div class="left d-flex justify-content-between align-items-center" data-wow-duration="0.4s" data-wow-delay="0s">
		                <img src="@/assets/logobom.png" alt="" srcset="">
		            </div>
		            <div class="center  " data-wow-duration="0.4s" data-wow-delay="0.1s">
		                <div class="title">联系我们</div>
		                <ul>
		                    <li>
		                        <img src="@/assets/iphone.png" alt="" srcset="">
		                        <span>4006699955</span>
		                    </li>
		                    <li>
		                        <img src="@/assets/qq.png" alt="" srcset="">
		                        <span>25538</span>
		                    </li>
		                    <li>
		                        <img src="@/assets/message.png" alt="" srcset="">
		                        <span>25538@qq.com</span>
		                    </li>
		                </ul>
		            </div>
		            <div class="right" data-wow-duration="0.4s" data-wow-delay="0.2s">
		                <div class="lists d-flex align-items-center">
		                    <div class="list">
		                        <span class="title">Android扫码下载</span>
		                        <img src="@/assets/code.png" alt="" srcset="">
		                    </div>
		                </div>
		            </div>
		        </div>
				<div class="footer d-flex justify-content-center align-items-center">
					广州禅缘信息科技有限公司
				    <a class="cursor-pointer" href="https://beian.miit.gov.cn/">粤ICP备2024232378号-1</a>&nbsp;&nbsp;
				</div>
		        <!-- <div class="footer d-flex justify-content-center align-items-center">
					江西尽享好物科技有限公司版权所有
		            <a class="cursor-pointer" href="https://beian.miit.gov.cn/">赣ICP备2023012056号-4</a>&nbsp;&nbsp;
		        </div> -->
		    </div>
		</div>
		<div v-else class="footnoteHome bg-color-white d-flex justify-content-center">
		    <div class="col-11 col-xl-8 foot-content">
		        <!-- <div class="content">
		            <div class="iphfootxt">
		                联系我们
		            </div>
		            <div class="center iphfootnum" data-wow-duration="0.4s" data-wow-delay="0.1s">
						<div>
							<span>25538</span>
							<img src="@/assets/qq.png" alt="" srcset="">
						</div>
						<div class="midline"></div>
						<div>
							<img src="@/assets/message.png" alt="" srcset="">
							<span>25538@qq.com</span>
						</div>
		            </div>
					<div class="logoimg">
						<img src="@/assets/iphfootlogo.png" alt="" />
					</div>
					<div style="text-align: center;">
						在线咨询 4006699955
					</div>
		            
		        </div> -->
		        <div class="footer d-flex justify-content-center align-items-center">
					江西尽享好物科技有限公司版权所有&nbsp;
		            <a class="cursor-pointer" href="https://beian.miit.gov.cn/"> 赣ICP备2023012056号-4</a>&nbsp;&nbsp;
		        </div>
		    </div>
		</div>
	</div>
    <customerHome
    ref="customerHomeRef"
    />
</template>

<script setup>
import customerHome from './customer.vue';
import { ref,reactive } from 'vue';
import { mainStore } from "@/sheep/store"
const props = defineProps({
	data:{
		type:Boolean,
		default:false
	}
})

const pageData = reactive({
    isAiHua:window.location.host.indexOf("aihua"),
    isKfShow:false
})
// console.log(pageData.isAiHua)
const store = mainStore()
const customerHomeRef = ref(null);
function clkonline(){
    // customerHomeRef.value.show();
}
function go(){
    window.open('https://si.trustutn.org/info?sn=139230308040939782595&certType=1', '_blank');
}
</script>

<style lang="scss" scoped>
.footnoteHome{
	height: 46.8rem;
    background: url(@/assets/footbgc.png) no-repeat;
    background-size: 100% 100%;
    .content{
        padding: 5.2rem 0 3.3rem 0;
        .left{
            img{
				margin-left: 10rem;
				margin-top: -15%;
				width: 33rem;
                height: 11.4rem;
            }
        }
        .center{
            .title{
                font-size: 2.4rem;
                font-family: '宋体';
                font-weight: 900;
                color: #fff;
                margin-bottom:2.6rem;
            }
            ul{
                li{
                    margin-bottom: 1.7rem;
                    display:flex;
                    align-items: center;
                    img{
                        width: 2.1rem;
                        margin-right: 1rem;
                    }
                    span{
                        font-size: 2.4rem;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #fff;
                    }
                }
                li:last-of-type{
                    margin-bottom: 0;
                }
            }
        }
        .right{
            .lists{
                .list{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin-right: 6.3rem;
                    span{
                        font-size: 2.4rem;
                        font-family: '宋体';
                        font-weight: 900;
                        color: #fff;
                        margin-bottom:2.6rem;
                    }
                    img{
                        width:16rem;
                    }
                }
            }
        }
    }
    .footer{
		position: absolute;
		left: 30%;
		bottom: 4.6rem;
        font-size: 2rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #fff;
        line-height: 2rem;
        a{
            color: #fff;
        }
    }
    .footerSD{
        padding-top: 0;
        .sdImg{
            width: 12rem;
        }
        div{
            margin-top: 1.5rem;
            img{
                margin-right: 0.5rem;
            }
        }
    }
}


@media screen and (max-width: 767px) {
	
	.footer{
		color: red;
		position: absolute;
		left: 0% !important;
		bottom: 2rem !important;
		width: 100%;
		text-align: center;
	    font-size: 1.5rem !important;
	    font-family: PingFangSC-Regular, PingFang SC;
	    font-weight: 400;
	    color: #B1B1B1 !important;
	    line-height: 2rem;
		a{
			color: #B1B1B1 !important;
		}
	}
    .footnoteHome {
		width: 100%;
		height: 3.8rem;
		background: #F9F9F7;
        // background: url(@/assets/iphfootbgc.png) no-repeat;
        // background-size: 100% 100%;
		font-size: 2.4rem;
		font-family: '宋体';
		color: #fff;
		.content{
		    padding: 2.6rem 0 2.4rem 0;
		}
		.logoimg{
			text-align: center;
			margin-bottom: 2.7rem;
			img{
				width: 10.6rem;
				height: 10.6rem;
			}
		}
		.iphfootnum{
			font-weight: 500;
			display: flex;
			align-items: center;
			padding-left: 24.5%;
			box-sizing: border-box;
			margin-bottom: 1.9rem;
			.midline{
				width: 0.1rem;
				height: 2.2rem;
				margin: 0 4rem;
				background-color: #979797;
				opacity: 0.47;
			}
			>div{
				height: 3.3rem;
				line-height: 3.3rem;
				display: flex;
				align-items: center;
				img{
					width: 2.6rem;
					height: 2.6rem;
				}
			}
		}
		.iphfootxt{
			text-align: center;
			font-weight: 900;
			color: #fff;
			margin-bottom:2.6rem;
		}
        .foot-app{
            background: white;
            border-radius: $border-radius-con;
            margin-top: $align-16;
            padding:3rem $align-16 ;
            .content{
                flex-wrap: wrap;
                padding: 0;
                background: white;
                
                .left,.center,.right{
                    width: 100%;
                    justify-content: center!important;
                    margin-bottom:3rem;
                }
                .center{
                    display: flex;
                    margin-bottom: 0;
                    
                    .title{
                        writing-mode: tb;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-bottom: 0!important;
                        margin-right: 3rem;
                    }
                }
                
            }
        }
        
    }
    .footer-con{
        border-radius: 0!important;
        flex-direction: column;
        align-items: center;
        display: flex;
        justify-content: center;
        .right {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .logo{
                height: 3.6rem;
            }
            .lists{
                
            }
        }
    }
}

.right{
    .lists{
        .list{
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-right: 6.3rem;
            span{
            
                font-size: 2rem;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #333333;
                margin-bottom:1.5rem;
            }
            img{
                width:13.5rem;
            }
        }
        .list:last-child{
            margin-right: 0;
        }
    }
}

</style>