<!--
 * @Author: guodm guodm@9856
 * @Date: 2023-08-09 09:27:54
 * @LastEditors: guodm guodm@9856
 * @LastEditTime: 2023-08-22 15:39:06
 * @FilePath: \website\src\pages\components\banner.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
	<div style="position: relative;" class="banner" v-if="store.isApp">
		<img class="banner-bg" src="@/assets/iphonebanner.png" alt="" srcset="">
		<img id="downclick" @click="gotoApp" class="iphonebtn" src="@/assets/iphdown.png" alt="" />
		<view class="dowNumber">
			<view style="margin-right: 0.5rem;">
				<img class="downimg" src="@/assets/down.png" alt="" />
			</view>
			<view>{{pageData.dowNumber}}万+用户已下载</view>
			
		</view>
		<div class="iphtoptree"></div>
	</div>
	<div class="banner" v-else>
		<img class="banner-bg" src="@/assets/banner.png" alt="" srcset="">
		<div class="container-banner row no-gutters d-flex justify-content-center">
			<div class="down col-11 col-xl-8">
				<div class="content cursor-pointer">
					<div class="downDmo" @click="gotoApp">
						<span>立即下载</span>
						<img src="@/assets/arrow.png" alt="" srcset="">
					</div>
					<div>
						<img class="code" src="@/assets/code.png" alt="" srcset="">
					</div>
				</div>
			</div>
		</div>
		<!-- <div class="bottom-img row no-gutters d-flex justify-content-center ">
            <img class="col-11 col-xl-8" src="@/assets/serve.png" alt="" srcset="">
        </div> -->
	</div>
</template>

<script setup>
	import {
		mainStore
	} from "@/sheep/store"
	import { onMounted,reactive} from 'vue';
	const store = mainStore()
	const pageData = reactive({
		dowNumber:15,
	})
	
	onMounted(() => {
		if(store.isApp){
			dayNum()
		}
	})
	function dayNum(){
		  var d1 = new Date('2024/6/28');
		  var d2 = new Date();
		  var timeDiff = Math.abs(d2.getTime() - d1.getTime());
		  var days = Math.floor(timeDiff / (1000 * 3600 * 24));
		  pageData.dowNumber += days*0.2
	}
	// function gotoApp(){
	//     if(store.isApp){
	//         location.href='https://zx-download.aihua.com/'
	//     }

	// }
</script>

<style lang="scss" scoped>
	.iphtoptree {
		position: absolute;
		top: 5rem;
		right: 0;
		width: 17.7rem;
		height: 40.1rem;
		background: url(@/assets/iphtoptre.png) no-repeat;
		background-size: 100% 100%;
		z-index: 2;
	}
	.downDmo {
		width: 20rem;
		height: 6rem;
		line-height: 6rem;
		text-align: center;
		background: #BC3032;
		border-radius: 1.2rem;
		color: white;
		font-size: 2.8rem;

		img {
			width: 1.2rem;
			height: 2rem;
			margin-left: 1.2rem;
		}
	}

	.dowNumber {
		position: absolute;
		right: 4.6rem;
		top: 53%;
		padding: 0.5rem 1.8rem 0.4rem;
		box-sizing: border-box;
		font-size: 1.8rem;
		color: #fff;
		z-index: 6;
		background: rgba(0,0,0,0.4);
		border-radius: 2.1rem;
		display: flex;
		align-items: center;
		.downimg{
			width: 2.2rem;
			height: 2rem;
		}
	}

	.iphonebtn {
		position: absolute;
		right: 2.6rem;
		top: 42%;
		width: 26rem;
		height: 8rem;
		animation: breathing 3s infinite;
		z-index: 5;
	}

	@keyframes breathing {
		0% {
			transform: scale(1);
		}

		50% {
			transform: scale(1.2);
		}

		100% {
			transform: scale(1);
		}
	}

	.banner {
		position: relative;

		// padding-top: 8.8rem;
		.banner-bg {
			width: 100%;
		}

		.bottom-img {
			position: absolute;
			width: 100%;
			left: 0;
			bottom: 0;
		}

		.container-banner {
			position: absolute;
			top: 28%;
			left: 8.7%;
			width: 100%;
			// .downDmo{
			//     padding: 0.5vw 2vw;
			//     background: #BC3032;
			//     border-radius: 12px;
			//     display: inline-flex;
			//     align-items: center;
			//     color:white;
			//     font-size: 2.8rem;
			//     font-weight: 500;
			//     img{
			//         height: 2rem;
			//         margin-left: 1.2rem;
			//     }
			// }

			.content {
				width: fit-content;
			}

			.down {
				.code {
					margin-top: 2rem;
					width: 20rem;
					height: 20rem;
					opacity: 0;
					transition: all .4s;
				}
			}

			.content:hover {
				.code {
					opacity: 1;
					transition: all .4s;
				}

			}
		}

		@media screen and (max-width: 767px) {
			.container-banner {
				bottom: 11.7188vw;

				.downDmo {
					padding: 2vw;
					font-size: 1.0938vw;
					// img{
					//     height: 1.0417vw;
					//     margin-left: .3646vw;
					// }
				}

			}
		}
	}
</style>